import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Nav from "../components/nav";
import Stats from "../components/Stats";
import Tabs from "../components/Tabs";
import Video from "../components/Video";
import Stepper from "../components/Stepper";
import TwoColumnsCards from "../components/TwoColumnsCards";

import partnerLogo from "../images/logo_partner.png";
import Hero from "../components/Hero";
import Logos from "../components/Logos";
import Feature from "../components/Feature";
import CTA from "../components/CTA";

import Salt1 from "../images/saltstack/salt1.png";
import Salt2 from "../images/saltstack/salt2.png";
import Salt3 from "../images/saltstack/salt3.png";
import Salt4 from "../images/saltstack/salt4.png";
import Salt5 from "../images/saltstack/salt5.png";
import Salt6 from "../images/saltstack/salt6.png";
import SaltComply from "../images/SaltStack_Comply_black.png";
import SaltProtect from "../images/SaltStack_Protect_black.png";
import Calendar from "../components/calendar";

const Tab1 = {
  title: "Remediate vulnerabilities and enforce compliance with SecOps",
  items: [
    {
      id: 1,
      title: "Orchestrated self-healing",
      description:
        "Keep critical systems properly configured to increase reliability and optimize resource utilization. SaltStack is the only platform with native event-driven IT automation that can immediately detect events triggered by system configuration drift, multiple integrations, or pre-defined infrastructure policies and enforce desired state. The SaltStack event-driven engine can also manage complex orchestration such",
      image: Salt1,
    },
    {
      id: 2,
      title: "Orchestrate and automate the work of IT",
      description:
        "SaltStack includes intelligent orchestration capabilities to manage both IT workflows and the automation of the work itself. For example, SaltStack can detect a vulnerability, create a ticket and notify an IT guy. SaltStack can also perform multi-step automation to patch the vulnerable systems.",
      image: Salt2,
    },
    {
      id: 3,
      title: "Hybrid infrastructure management, simplified",
      description:
        "SaltStack is built on a powerful event-driven automation engine that provides IT, devops and SRE professionals with a simple, declarative management framework for their entire global infrastructure. Whether you’re in the middle of a cloud migration, strictly on prem, or fully deployed on containers, SaltStack abstracts control of the underlying infrastructure so you can build a desired configuration once and enforce it across your entire data center. ",
      image: Salt3,
    },
  ],
};

const Tab2 = {
  title: "Remediate vulnerabilities and enforce compliance with SecOps",
  items: [
    {
      id: 1,
      title: "IT systems that start compliant and stay that way",
      description:
        "The first step to a compliant IT environment is ensuring that every new system meets security standards. SaltStack allows IT and security teams to apply a simple “security policy as code” approach to the creation and deployment of every new system, ensuring proper compliance on day 0 and beyond. ",
      image: Salt4,
    },
    {
      id: 2,
      title: "Find it. Fix it. Fast",
      description:
        "Chasing compliance drift on existing systems can feel like a recurring nightmare. SaltStack actively scans for compliance drift and provides automated remediation steps to enforce defined security policies—saving resources, improving security posture, and reducing risk.",
      image: Salt5,
    },
    {
      id: 3,
      title: "Vulnerabilities remediation",
      description:
        "Security scanners can report avalanches of vulnerabilities that operations teams must translate into IT tickets, investigate, prioritize, test, fix, and then report back to security. SaltStack brings the power of automation to SecOps by scanning IT systems for vulnerabilities and then providing out-of-the-box automation workflows that remediate them.",
      image: Salt6,
    },
  ],
};
const SusePage = ({ data: { susePage } }) => {
  const { data } = susePage;
  return (
    <Layout
      title={data.page_title.text}
      description={data.page_description.text}
      thumbnail={data.share_preview.url}>
      <Nav isDark></Nav>
      <header className="pt-24 sm:pt-24 md:pt-24 lg:pt-56 bg-gray-900 pb-24">
        <div className="mx-auto max-w-screen-xl">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
              <div>
                <h1 className="mt-4 mb-8 text-3xl tracking-tight leading-10 font-bold text-white sm:mt-5 sm:leading-none sm:text-5xl lg:mt-6 lg:text-4xl xl:text-5xl">
                  <span className="text-primary-400">Zen</span>Ops <br />{" "}
                  Partenaire SUSE pour le déploiement d’infrastructures Open
                  Source.
                </h1>
                <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
                  <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
                    <a
                      href="#contact"
                      className="hover:-translate-y-1 transform w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring transition duration-150 ease-in-out">
                      Demandez une démo !
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6 flex justify-center items-center">
              <div className="px-4 py-8 sm:px-10">
                <svg style={{ width: "250px" }} viewBox="0 0 474.72 586.65">
                  <line
                    class="stroke-primary path"
                    x1="94.29"
                    y1="195.13"
                    x2="238.39"
                    y2="423.98"
                  />

                  <line
                    class="stroke-primary path"
                    x2="176.01"
                    y2="195.13"
                    x1="320.13"
                    y1="423.98"
                  />

                  <line
                    class="stroke-primary path"
                    x1="225"
                    y1="143.15"
                    x2="399.53"
                    y2="420.29"
                  />

                  <circle
                    class="fill-white"
                    cx="351.65"
                    cy="463.57"
                    r="123.07"
                  />
                  <circle
                    class="fill-white"
                    cx="123.69"
                    cy="123.69"
                    r="123.69"
                  />
                  <path
                    class="fill-primary-900"
                    d="M56.72,162.23l61.62-74.54h-60V69.23h84.42V85.2l-59.68,74.42h60v18.6H56.72V162.23z"
                  />
                  <path
                    class="fill-grey-900"
                    d="M116.96,151.96c-1.03-0.9-2-1.88-2.91-2.91c-2.17-2.81-3.92-5.92-5.19-9.23l-9.62,12.14
	H116.96z"
                  />
                  <path
                    class="fill-grey-900"
                    d="M186.79,103.85c-2.49-6.53-6.13-12.57-10.75-17.82c-4.67-5.28-10.33-9.59-16.66-12.69
	c-2.86-1.38-5.85-2.47-8.93-3.23v20.15c0.55,0.23-0.23-0.14,0.35,0.14c3.87,1.94,7.27,4.7,9.97,8.08c2.78,3.48,4.92,7.42,6.3,11.65
	c1.52,4.4,2.3,9.03,2.31,13.68c0,4.51-0.7,9-2.08,13.29c-1.34,4.25-3.42,8.22-6.14,11.75c-2.69,3.47-6.1,6.32-9.99,8.35h-0.18v19.85
	c2.67-0.63,5.28-1.49,7.8-2.56c6.37-2.9,12.11-7.02,16.89-12.14c4.73-5.11,8.48-11.05,11.05-17.52c2.65-6.6,4.01-13.65,3.99-20.77
	C190.66,117.14,189.33,110.29,186.79,103.85z"
                  />
                  <path
                    class="fill-grey-900"
                    d="M90.88,150L90.88,150l15.28-19.22l0,0L90.88,150z"
                  />
                  <path
                    class="fill-grey-900"
                    d="M92.03,95.42c-1.43,2.36-2.69,4.82-3.78,7.36c-1.68,4.29-2.8,8.77-3.35,13.34L102,95.35
	L92.03,95.42z"
                  />
                  <g id="Layer_1_1_">
                    <g>
                      <path
                        class="fill-dark-green-suse"
                        d="M424.99,524.2H408.1c-1.3,0-2.36-1.06-2.36-2.36v-8.53h16.3c1.4,0,2.54-1.13,2.54-2.54
			c0-1.4-1.14-2.54-2.54-2.54h-16.3v-8.4c0-1.3,1.06-2.36,2.36-2.36h16.89c1.47,0,2.66-1.19,2.66-2.66s-1.19-2.66-2.66-2.66H408.1
			c-4.23,0-7.68,3.45-7.68,7.69v22c0,4.24,3.45,7.69,7.68,7.69h16.89c1.47,0,2.66-1.19,2.66-2.66
			C427.65,525.39,426.46,524.2,424.99,524.2 M375.33,508.3c-3.57-0.64-6.05-1.4-7.44-2.28s-2.09-2.08-2.09-3.58
			c0-1.61,0.71-2.94,2.14-3.97c1.43-1.03,3.44-1.55,6.03-1.55c2.63,0,4.74,0.51,6.31,1.52c0.85,0.55,1.62,1.27,2.32,2.18
			c1.03,1.33,2.97,1.51,4.22,0.38c1.18-1.06,1.28-2.89,0.22-4.07c-1.28-1.43-2.74-2.57-4.37-3.4c-2.42-1.24-5.35-1.86-8.77-1.86
			c-2.93,0-5.5,0.51-7.7,1.52c-2.2,1.01-3.88,2.39-5.05,4.11c-1.17,1.73-1.75,3.65-1.75,5.75c0,1.99,0.46,3.69,1.38,5.07
			c0.92,1.39,2.4,2.56,4.43,3.5c2.03,0.94,4.75,1.73,8.17,2.37c3.42,0.64,5.82,1.37,7.19,2.2c1.37,0.83,2.06,1.92,2.06,3.27
			c0,1.69-0.76,3-2.29,3.92c-1.52,0.92-3.62,1.38-6.28,1.38c-2.82,0-5.18-0.53-7.08-1.58c-1.08-0.6-2.05-1.4-2.92-2.39
			c-1.08-1.26-3.02-1.28-4.19-0.11l-0.01,0.01c-1.07,1.07-1.15,2.8-0.14,3.93c3.22,3.59,8.02,5.38,14.39,5.38
			c2.93,0,5.52-0.44,7.75-1.32c2.24-0.88,3.97-2.15,5.21-3.8c1.24-1.65,1.86-3.57,1.86-5.75c0-2.03-0.45-3.73-1.35-5.1
			c-0.9-1.37-2.34-2.52-4.31-3.44C381.33,509.67,378.67,508.91,375.33,508.3 M292.07,508.32c-3.57-0.64-6.05-1.4-7.44-2.28
			c-1.39-0.89-2.09-2.08-2.09-3.58c0-1.61,0.72-2.94,2.14-3.97c1.43-1.03,3.44-1.55,6.03-1.55c2.63,0,4.73,0.51,6.31,1.52
			c0.85,0.55,1.62,1.27,2.32,2.18c1.03,1.33,2.97,1.51,4.22,0.38c1.18-1.07,1.28-2.89,0.22-4.07c-1.28-1.43-2.74-2.56-4.37-3.4
			c-2.42-1.24-5.35-1.86-8.77-1.86c-2.93,0-5.5,0.51-7.7,1.52c-2.2,1.02-3.88,2.39-5.05,4.11c-1.16,1.73-1.75,3.65-1.75,5.75
			c0,1.99,0.46,3.69,1.38,5.07c0.92,1.39,2.4,2.56,4.43,3.5c2.03,0.94,4.75,1.73,8.17,2.37c3.42,0.64,5.82,1.37,7.19,2.2
			c1.37,0.82,2.06,1.92,2.06,3.27c0,1.69-0.76,3-2.28,3.92c-1.52,0.92-3.62,1.38-6.29,1.38c-2.82,0-5.18-0.53-7.08-1.58
			c-1.09-0.6-2.06-1.4-2.91-2.39c-1.08-1.25-3.02-1.28-4.19-0.11l-0.01,0.01c-1.07,1.07-1.15,2.8-0.14,3.93
			c3.22,3.59,8.02,5.38,14.39,5.38c2.93,0,5.52-0.44,7.75-1.33c2.24-0.88,3.97-2.15,5.21-3.8c1.24-1.65,1.86-3.57,1.86-5.75
			c0-2.03-0.45-3.73-1.35-5.1c-0.9-1.37-2.34-2.52-4.31-3.44C298.08,509.68,295.42,508.92,292.07,508.32 M347.6,494.72v20.46
			c0,4.89-1.3,8.58-3.89,11.08c-2.6,2.5-6.37,3.75-11.33,3.75c-4.96,0-8.74-1.25-11.33-3.75c-2.59-2.5-3.89-6.19-3.89-11.08v-20.46
			c0-1.68,1.36-3.04,3.04-3.04c1.68,0,3.05,1.36,3.05,3.04v19.73c0,3.53,0.74,6.14,2.23,7.81c1.49,1.67,3.78,2.51,6.91,2.51
			c3.12,0,5.42-0.84,6.91-2.51c1.48-1.67,2.23-4.27,2.23-7.81v-19.73c0-1.68,1.36-3.04,3.05-3.04
			C346.24,491.67,347.6,493.04,347.6,494.72"
                      />
                      <path
                        class="fill-green-suse"
                        d="M420.19,424.34c-0.82,0.54-1.92,0.54-2.73,0c-1.34-0.89-1.47-2.75-0.39-3.84c0.96-1,2.55-1,3.51,0
			C421.66,421.59,421.53,423.45,420.19,424.34 M424.1,418.91c1.56,6.64-4.41,12.61-11.05,11.05c-3.38-0.79-6.06-3.48-6.85-6.86
			c-1.56-6.64,4.41-12.6,11.04-11.04C420.62,412.85,423.3,415.53,424.1,418.91 M379.4,454.61c0.76,1.09,1.39,2.14,1.75,3.2
			c0.25,0.75,0.57,1.74,1.31,2.13c0.04,0.02,0.08,0.04,0.12,0.05c1.35,0.49,4.84,0.41,4.84,0.41h6.41c0.55,0.01,5.36,0,5.24-0.54
			c-0.58-2.57-3.56-3.03-5.82-4.38c-2.09-1.25-4.07-2.66-4.97-5.09c-0.47-1.25-0.19-4.14,0.62-5.2c0.59-0.76,1.45-1.27,2.39-1.47
			c1.03-0.22,2.1-0.03,3.14,0.07c1.27,0.13,2.53,0.36,3.8,0.52c2.45,0.32,4.92,0.45,7.4,0.38c4.08-0.11,8.17-0.76,12.03-2.08
			c2.7-0.91,5.36-2.13,7.65-3.83c2.61-1.94,1.93-1.76-0.72-1.49c-3.17,0.33-6.36,0.37-9.54,0.18c-2.96-0.17-5.89-0.52-8.57-1.9
			c-2.11-1.09-3.93-2.18-5.6-3.87c-0.25-0.25-0.41-1,0.05-1.47c0.44-0.46,1.39-0.19,1.68,0.05c2.92,2.44,7.27,4.45,11.78,4.67
			c2.44,0.12,4.81,0.17,7.25,0.06c1.22-0.05,3.06-0.05,4.28-0.06c0.63-0.01,2.35,0.17,2.67-0.49c0.1-0.2,0.09-0.42,0.08-0.64
			c-0.36-9.75-1.08-20.76-11.29-25.42c-7.62-3.48-19.04-8.88-23.86-11.12c-1.12-0.53-2.43,0.31-2.43,1.55
			c0,3.26,0.17,7.94,0.17,12.2c-2.31-2.35-6.2-3.84-9.17-5.2c-3.37-1.55-6.85-2.85-10.38-3.96c-7.13-2.21-14.5-3.56-21.91-4.3
			c-8.4-0.84-16.95-0.44-25.24,1.27c-13.63,2.83-27.03,9.39-37.2,18.97c-6.25,5.89-11.14,14.25-11.48,22.74
			c-0.47,12.01,2.89,18.47,9.08,25.12c9.87,10.6,31.1,12.08,39.7-0.49c3.87-5.66,4.71-13.33,1.9-19.58
			c-2.81-6.25-9.26-10.77-16.1-11c-5.31-0.17-10.97,2.53-13,7.44c-1.55,3.75-0.67,8.38,2.16,11.29c1.1,1.14,2.59,2.06,4.22,1.7
			c0.96-0.21,1.76-0.93,1.91-1.91c0.21-1.44-1.05-2.37-1.82-3.47c-1.4-1.99-1.11-4.98,0.64-6.67c1.48-1.43,3.66-1.85,5.71-1.85
			c1.91,0,3.87,0.35,5.52,1.31c2.32,1.36,3.86,3.86,4.39,6.5c1.59,7.89-4.82,14.3-13.52,14.8c-4.45,0.26-8.98-0.91-12.45-3.7
			c-8.8-7.07-10.95-21.54-0.89-29.26c9.54-7.33,21.59-5.44,28.69-1.63c5.69,3.05,9.92,8.03,13.13,13.54
			c1.61,2.77,2.98,5.67,4.26,8.61c1.23,2.83,2.37,5.68,4.82,7.75c1.62,1.37,3.63,1.32,5.75,1.32h12.14c1.65,0,1.25-1.1,0.53-1.83
			c-1.61-1.64-3.92-2.02-6.06-2.61c-4.89-1.35-4.39-7.82-3.04-7.82c4.38,0,4.51,0.13,8.35,0.08c5.53-0.07,7.2-0.4,11.53,1.2
			C375.73,450.29,377.95,452.55,379.4,454.61"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="py-12 bg-gray-100 pt-24" id="infra">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-primary-600 sm:leading-10 bg-gray-100 py-2 z-10 lg:text-center"
            style={{ position: "sticky", top: "50px" }}>
            Pilotage de l’infrastructure
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto lg:text-center">
            Solutions de gestion d’infrastructures informatiques Open Source
            pour donner une orientation software-defined à votre IT
          </p>

          <Video
            src={"453590558"}
            title={
              "Une infrastructure qui s’adapte au rythme des déploiements de nouveaux services et de nouvelles applications."
            }
          />
        </div>
      </div>

      <div className="py-12 bg-white">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8 ">
          <h3
            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-primary-600 bg-white sm:leading-10 py-2 z-10 lg:text-center"
            style={{ position: "sticky", top: "50px" }}>
            SUSE Manager vous aide à créer une infrastructure Software-defined.
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto lg:text-center">
            Déploiement plus rapide. <br />
            Réduction des efforts et des coûts. <br />
            SUSE Manager a été conçu pour aider les équipes DevOps et
            informatiques de votre entreprise à réduire la complexité et à
            reprendre le contrôle de vos ressources informatiques.
          </p>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8 mt-12">
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                  />
                </svg>
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Intégration et gestion faciles
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  des serveurs Linux connectés au réseau, des périphériques de
                  périmètre IoT à l’environnement Kubernetes, où qu’ils se
                  trouvent ; dans votre datacenter, un datacenter tiers ou le
                  cloud public.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                  />
                </svg>
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Outil unique pour le déploiement automatisé
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  de modèles de système d’exploitation rodés (bare-metal,
                  machine virtuelle ou conteneur) sur des dizaines de milliers
                  de serveurs et de périphériques IoT ; pour une configuration
                  et un provisioning plus rapides, cohérents et reproductibles
                  sans compromettre la vitesse ni la sécurité.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  La gestion automatique des correctifs
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  vous permet de déployer des correctifs en fonction du canal
                  logiciel affecté, ce qui vous aide à réduire les coûts.
                </p>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                  />
                </svg>
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Gestion simplifiée
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  par le biais de la visualisation graphique des systèmes Linux
                  et de leurs relations, avec possibilité de les organiser en
                  groupes logiques en fonction de balises (par exemple,
                  emplacement, rack, fournisseur, architecture de CPU, RAM,
                  etc.).
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                  />
                </svg>
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Audit de conformité détaillé
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  et création de rapports avec possibilité d’effectuer le suivi
                  de toutes les modifications matérielles et logicielles
                  apportées à votre infrastructure Linux gérée, pour assurer la
                  conformité aux règles de sécurité internes.
                </p>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <div className="mt-5">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Identification rapide des systèmes
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  déployés dans le cloud hybride et les infrastructures de
                  conteneur qui ne respectent pas les profils/modèles rodés
                  définis par vos règles de sécurité internes afin de résoudre
                  rapidement les problèmes de conformité.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Banner  */}
      {/* <div className="bg-gray-900 text-white">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex flex-col items-start">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight  sm:text-4xl sm:leading-10">
            Vous voulez en savoir plus ?
            <br className="inline sm:inline" />
            <span className="text-primary-600" id="newsletter-headline">
              Plannifiez une démo avec un de nos experts SUSE !
            </span>
          </h2>
          <div className="mt-6 rounded-md shadow sm:flex-shrink-0 w-auto inline-block">
            <CTA form_id={"09f0144d-28ad-4587-8be6-9a851ac31fdf"} />
          </div>
        </div>
      </div> */}

      {/* Positionnement SaltStack */}
      {/* <div className="py-12 bg-gray-200">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div
              href="#"
              className="rounded-md bg-gray-900 shadow-lg transform hover:-translate-y-1 transition duration-300 "
            >
              <div className="max-w-sm overflow-hidden sm:mt-5 text-white p-8 m-auto rounded-md bg-gray-900 mt-10">
                <div className="flex items-center justify-center h-12 w-12 text-white mx-6 my-4 ">
                  <img
                      src={p.pillar_icon.url}
                      alt={p.pillar_icon.alt}
                      data-sal="Fade"
                    />
                </div>
                <div className="px-6 py-4 flex flex-col">
                  <h5
                    className="text-xl leading-6 font-bold text-white"
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease-in-out"
                    data-sal-duration="500"
                  >
                    Define
                  </h5>
                  <p
                    className="mt-2 text-base leading-6 text-gray-500"
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease-in-out"
                    data-sal-duration="500"
                  >
                    Build custom policies with industry-standard compliance
                    profiles, such as CIS and DISA STIGS, then apply them
                    automatically across your digital footprint.
                  </p>
                </div>
              </div>
            </div>
            <div
              href="#"
              className="rounded-md bg-gray-900 shadow-lg transform hover:-translate-y-1 transition duration-300 "
            >
              <div className="max-w-sm overflow-hidden sm:mt-5 text-white p-8 m-auto rounded-md bg-gray-900 mt-10">
                <div className="flex items-center justify-center h-12 w-12 text-white mx-6 my-4 ">
                  <img
                      src={p.pillar_icon.url}
                      alt={p.pillar_icon.alt}
                      data-sal="Fade"
                    />
                </div>
                <div className="px-6 py-4 flex flex-col">
                  <h5
                    className="text-xl leading-6 font-bold text-white"
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease-in-out"
                    data-sal-duration="500"
                  >
                    Detect
                  </h5>
                  <p
                    className="mt-2 text-base leading-6 text-gray-500"
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease-in-out"
                    data-sal-duration="500"
                  >
                    Run continuous, item-level checks to locate vulnerabilities
                    and non-compliant systems or applications anywhere in your
                    environment.
                  </p>
                </div>
              </div>
            </div>
            <div
              href="#"
              className="rounded-md bg-gray-900 shadow-lg transform hover:-translate-y-1 transition duration-300 "
            >
              <div className="max-w-sm overflow-hidden sm:mt-5 text-white p-8 m-auto rounded-md bg-gray-900 mt-10">
                <div className="flex items-center justify-center h-12 w-12 text-white mx-6 my-4 ">
                  <img
                      src={p.pillar_icon.url}
                      alt={p.pillar_icon.alt}
                      data-sal="Fade"
                    />
                </div>
                <div className="px-6 py-4 flex flex-col">
                  <h5
                    className="text-xl leading-6 font-bold text-white"
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease-in-out"
                    data-sal-duration="500"
                  >
                    Enforce
                  </h5>
                  <p
                    className="mt-2 text-base leading-6 text-gray-500"
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease-in-out"
                    data-sal-duration="500"
                  >
                    Use autonomous policy enforcement to fix violations
                    automatically. Or kick off a remediation workflow so your
                    teams can flag and prioritize issue resolution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="py-12 bg-gray-900 " id="contact">
        <div className="max-w-l mx-auto lg:max-w-screen-xl ">
          {/* <img
            className="mx-auto  rounded shadow"
            src="https://www.hubspot.fr/hs-fs/hubfs/assets/hubspot.com/parrotdad/feature-pages/sales-meetings-1.png?width=1025&name=sales-meetings-1.png"
          /> */}
          <div className="max-w-3xl mx-auto lg:max-w-none">
            <p className="py-8 mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none text-center">
              Plannifiez une démo avec nos consultants SUSE
            </p>
          </div>
          <div
            className="meetings-iframe-container"
            data-src="https://events.zenops.fr/meetings/arnaud59/speed-dating-consulting-suse?embed=true"></div>
          <script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
          <Calendar />
        </div>
      </div>
      <Footer title={"ZenOps vous accompagne sur la solution SUSE"} />
    </Layout>
  );
};

export default SusePage;

export const pageQuery = graphql`
  query SuseQuery {
    susePage: prismicSusepage {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        share_preview {
          url
        }
      }
    }
  }
`;

// export const pageQuery = graphql`
//   query PartnerQuery {
//     partnerPage: prismicPartnerpage {
//       data {
//         title {
//           text
//         }
//         body {
//           ... on PrismicPartnerpageBodyTrends {
//             id
//             primary {
//               key_number_title {
//                 text
//               }
//               key_numbers_intro {
//                 raw
//               }
//             }
//             items {
//               key_number {
//                 text
//               }
//               key_number_details {
//                 text
//               }
//             }
//           }
//           ... on PrismicPartnerpageBodyFeatures {
//             primary {
//               features_title {
//                 text
//               }
//             }
//             items {
//               feature_title {
//                 text
//               }

//               feature_label {
//                 text
//               }
//               feature_image {
//                 fluid {
//                   ...GatsbyPrismicImageFluid
//                 }
//               }
//               feature_description {
//                 raw
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// export const pageQuery = graphql`
//   query IndexQuery {
//     homePage: prismicHomepage {
//       data {
